<template>
  <div
    :class="{'logged-user': isLoggedUser}"
    class="reset-password"
  >
    <h2>{{ $t('newPassword') }}</h2>
    <p class="help-text">
      {{ $t('passworHasBeenReset') }}
    </p>
    <form
      id="forgotPasswordForm"
      @submit.prevent="resetPassword"
    >
      <div class="form-group">
        <Password
          v-model="password"
          v-validate="{ required: true, min: passwordLength, regex: passwordRegex }"
          :label="$t('createPassword')"
          :show-password-criteria="true"
          name="password"
        />
        <span
          v-show="errors.has('password') && hasErrorFromFromApi('password')"
          class="error"
        >{{ errors.first('password') }}</span>
      </div>
      <div class="form-group pt-5">
        <button
          :disabled="!password || errors.any()"
          type="button"
          class="btn btn-primary btn-block"
          @click.prevent="resetPassword"
        >
          {{ $t('saveNewPassword') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ErrorsDictionary } from '@/mixins/ErrorsDictionary'
import { mapGetters } from 'vuex'
import Password from '@/components/security/Password'

export default {
  name: 'ResetPassword',
  components: {
    Password
  },
  mixins: [ErrorsDictionary],
  props: ['isClinician'],
  data () {
    return {
      passwordLength: 12,
      password: '',
      isLoggedUser: this.$route.name === 'ResetPasswordLoggedUser'
    }
  },
  computed: {
    ...mapGetters({
      resetToken: 'getResetToken',
      accessCodeUUID: 'getAccessCodeUUID'
    })
  },
  mounted () {
    this.$validator.localize('en', this.errorsDictionary)
  },
  methods: {
    hasErrorFromFromApi (field) {
      return this.errors.items.find(i => i.field === field && !i.rule)
    },
    validateForm () {
      return this.$validator.validateAll()
    },
    validateFormCallback (formIsValid) {
      return formIsValid ? this.proceedWithRequestFlow() : false
    },
    proceedWithRequestFlow () {
      this.setSubmittingState()
      this.sendRequest()
        .then(this.requestSuccessCallback)
        .catch(this.requestCatchCallback)
        .finally(this.unsetSubmittingState)
    },
    requestSuccessCallback () {
      this.$toast.success({ message: this.$t('paswordResetSuccsess') })
      this.$store.commit('security/SET_RESET_TOKEN', null)
      return this.isClinician ? this.$emit('finished') : this.$route.name === 'ResetPassword' ? this.$router.push({ name: 'Login' }) : this.$router.push({ name: 'MyAccount' })
    },
    requestCatchCallback (error) {
      if (!error.response.status === 400) {
        return false
      }

      if (this.isInvalidPasswordResponse(error.response)) {
        const errorMsg = Array.isArray(error.response.data.errors.password) ? error.response.data.errors.password[0] : error.response.data.errors.password
        this.$validator.errors.add('password', errorMsg)
        return true
      }

      this.$router.push({ name: 'ForgotPassword' })
      const errorMsg = error.response.data.message ? error.response.data.message : this.$t('expiredResetToken')
      this.$handleApiError(errorMsg)
      return false
    },
    isInvalidPasswordResponse (errorResponse) {
      return errorResponse && errorResponse.data && errorResponse.data.errors && errorResponse.data.errors.password
    },
    getRequestData () {
      return {
        uuid: this.accessCodeUUID,
        password: this.password,
        token: this.resetToken
      }
    },
    sendRequest () {
      return this.$store.dispatch('RESET_PASSWORD', this.getRequestData())
    },
    setSubmittingState () {
      this.loader = this.$loading.show()
      return true
    },
    unsetSubmittingState () {
      if (this.loader) { this.loader.hide() }
      return true
    },
    resetPassword () {
      return this.validateForm().then(this.validateFormCallback)
    }
  }
}
</script>
<style lang="scss" scoped>
  .logged-user {
    padding: 30px 30px 10px 30px;
  }
</style>
